function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}


export const CheckName = (data)=>{
	if(emptyOrNot(data))
	return "Empty Field"

	else if(isNumeric(data))
	return "Numbers Not allowed"

	else if(data.length < 2)
	return "Name too short"

	else if(data.length > 200)
	return "Too many Characters"

	else
	return ""
}

export const CheckEmail = (data)=>{

	if(emptyOrNot(data))
	return "Empty Field"

	else if(!validateEmail(data))
	return "Invalid Email"

	else if(data.length > 200)
	return "Too many Characters"

	else
	return ""
}

export const CheckPhone = (num)=>{
	if (!(num.length >= 12 && num.length < 19) )
	return "Invalid Phone Number"

	else
	return ""

}

export const CheckMessage = (data)=>{
	if(emptyOrNot(data))
	return "Empty Field"

	else if(data.length < 10)
	return "Message too short"

	else if(data.length > 600)
	return "Too many Characters"

	else
	return ""
}

function emptyOrNot(data) {
	if(data === "")
	return true

	else
	return false
}

function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
  }