import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import "./CancelPolicy.css";
import Navbar from "../components/Navbar";

const CancelPolicy = () => {
  const [content, setContent] = useState({});
  const [loader, setLoader] = useState(false);
  const [listData, setListData] = useState([]);
  useEffect(() => {
    setLoader(true);
    getData();
	// eslint-disable-next-line
  }, []);

  const getData = async () => {
    const res = await axios.get(
      process.env.REACT_APP_CACELLATION_API
    );
	setContent(res.data);
	setLoader(false);

	setListData(res.data.CancellationPolicies);
  };

  if(loader===true)
  {
	  return (
		  <>
		<h1 style={{color:'#ffa223'}} className="fs-3 text-center mt-5 fw-bold">REFUND & CANCELLATION</h1>
		<Loader
        type="TailSpin"
        color="#ffa223"
        height={70}
        width={70}
		className="text-center mt-5"
		style={{height:'60vh'}}
        />
		</>
          
	  )
  }

  return (
	  <>
	<Navbar isDark={true} />
	<div className="cp-head">
        <h1 style={{color:'#ffa223'}} className="fs-3 fw-bold">{content.header}</h1>
      </div>
	  <div className="cancel-body">
		<h4 className="fs-5 fw-bold">{content.subheader}</h4>
		<p  style={{fontSize:14}}>{content.cancellationPolicyIntro}</p>
		{
			listData.map((data,i)=>(
				<div key={i}>
				<h2 key={i} className="list-head fs-5 fw-bold">{data.header}</h2>
				<p className="list-body">{data.explanation}</p>
				</div>
			))
		}

	  </div>
	</>
  );
};

export default CancelPolicy;
