import React from "react";
import Nav from "../components/Nav";
import "./About.scss";
import { useMediaQuery } from "react-responsive";

const About = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <div className="about-wrap">
      <Nav isDark={true} />
      {isTabletOrMobile && <h1>About Us</h1>}
      {isTabletOrMobile ? (
        <img effect="blur" className="about-bg-mb" src="./images/about-mb.png" alt="About" />
      ) : (
        <img loading="lazy" className="about-bg" src="./images/about.webp" alt="About" />
      )}
      <div className="about-det">
        <h1>Our Story</h1>
        <p>
          StrEatU began as an urge to splurge on the choicest street food
          Bangalore's streets had to offer. We figured that a platform to
          discover the best street food joints was simply missing. We decided to
          build one.
        </p>
      </div>

	  {!isTabletOrMobile && <div style={{marginTop:150}}></div> }

      <div className="about-card">
        <img src="./images/about/ag1.png" alt="Discovery" srcSet="./images/about/ag1@2x.png 2x,./images/about/ag1@3x.png 3x" />
        <div className="about-info">
          <h1 style={{ color: "#38da9f" }}>Discovery</h1>
          <p>
            Connect street food consumers to street food vendors through
            acurated discovery platform, allow them to rate, review and share
            their favourite street food places.
          </p>
        </div>
      </div>

      {isTabletOrMobile ? (
        <div className="about-card" style={{ marginLeft: 200 }}>
		<img src="./images/about/ag2.png" alt="Delivery" />
          <div className="about-info">
            <h1 style={{ color: "#ffa223" }}>Delivery</h1>
            <p>
              Allow street food consumers the convenience of ordering the best
              street food from the comfort of their homes.
            </p>
          </div>
        </div>
      ) : (
        <div className="about-card" style={{ marginLeft: 200 }}>
          <div className="about-info">
            <h1 style={{ color: "#ffa223" }}>Delivery</h1>
            <p>
              Allow street food consumers the convenience of ordering the best
              street food from the comfort of their homes.
            </p>
          </div>
          <img src="./images/about/ag2.png" alt="Delivery" srcSet="./images/about/ag2@2x.png 2x,./images/about/ag2@3x.png 3x" />
        </div>
      )}

      <div className="about-card">
        <img src="./images/about/ag3.png" alt="Advancement" srcSet="./images/about/ag3@2x.png 2x,./images/about/ag3@3x.png 3x" />
        <div className="about-info">
          <h1 style={{ color: "#38da9f" }}>Advancement</h1>
          <p>
            Improve street vending standards by focusing on hygiene, ingredient
            quality and the requisite infrastructure.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
