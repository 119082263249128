import Nav from "../components/Nav";
import "./Contact.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import firebase from "../firebase";
// import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import PhoneInput from "react-phone-number-input";
import {CheckName,CheckEmail,CheckPhone,CheckMessage} from "../components/Validations";
import "react-phone-number-input/style.css";

const Contact = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [content, setContent] = useState({});
  const [titles, setTitles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errors,setErrors] = useState({
	name:"",
	email:"",
	phone:"",
	message:""
});
  const [isloading, setloading] = useState(false); // For form submission
  const [num, setNum] = useState("");
  const db = firebase.firestore();

  useEffect(() => {
    setLoader(true);
    getData();
  }, []);
  const getData = async () => {
    const res = await axios.get(process.env.REACT_APP_FAQ_API);
    console.log(res.data);
    setContent(res.data);

    let dataSet = [];

    for (let k in res.data) {
      if (k === "contactNumber") continue;
      dataSet.push(k);
    }
    setTitles(dataSet);
    setLoader(false);
  };

  function makeid() {
    let length = 5;
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }



  const onSubmission = (e) => {
    setloading(true);
    e.target.disabled = true;

    const name = document.querySelectorAll("input")[0];
    const email = document.querySelectorAll("input")[1];
    const message = document.querySelectorAll("textArea")[0];

	let err = {
		name:CheckName(name.value),
		email:CheckEmail(email.value),
		phone:CheckPhone(num),
		message:CheckMessage(message.value)
	}

	setErrors(err);

    if (
      err.name!=="" || err.email!=="" || err.phone!=="" || err.message!==""
    ) {
      console.log("Validation Failed");
      e.target.disabled = false;
      setloading(false);
      return;
    }



    var d = new Date();
    var month = [];
    month[0] = "jan";
    month[1] = "feb";
    month[2] = "mar";
    month[3] = "apr";
    month[4] = "may";
    month[5] = "jun";
    month[6] = "jul";
    month[7] = "aug";
    month[8] = "sep";
    month[9] = "oct";
    month[10] = "nov";
    month[11] = "dec";
    var n = month[d.getMonth()];

    const dformat = n + d.getFullYear();

    // customerQueries/sep2021/queries

    // 	name

    // email

    // phone

    // message

    // timestamp

    // ticketStatus - pending

    db.collection("customerQueries")
      .doc(dformat)
      .collection("Queries")
      .add({
        name: name.value,
        email: email.value,
        phone: num,
        message: message.value,
        ticketStatus: "pending",
        timestamp: firebase.firestore.Timestamp.now().toDate(),
      })
      .then((id) => {
        document
          .getElementsByClassName("success-bg")[0]
          .classList.add("tactive");
        name.value = "";
        email.value = "";
        setNum("");
        message.value = "";
        e.target.disabled = false;
        setloading(false);
      });
  };

  const closeToast = () => {
    document
      .getElementsByClassName("success-bg")[0]
      .classList.remove("tactive");
  };

  return (
    <div className="contact-wrap">
      {isTabletOrMobile && <h1>Contact Us</h1>}
      <div className="success-bg">
        <div className="toast-card">
          <img src="./images/Contact/right.svg" alt="Success" />
          <div>
            <h3>Success</h3>
            <p>Streatu Executive will Connect with you within 24 hours.</p>
          </div>
          <button
            style={{ outline: "none" }}
            onClick={closeToast}
            className="ms-5 btn"
          >
            <img src="./images/Contact/cross.svg" alt="Cancel" />
          </button>
        </div>
      </div>
      <Nav isDark={true} />
      {isTabletOrMobile ? (
        <img
          className="about-bg-mb"
          src="./images/Contact/contact-mb.svg"
          alt="Contact"
        />
      ) : (
        <img
          loading="lazy"
          className="about-bg"
          src="./images/Contact/contactbg.png"
          alt="Contact"
        />
      )}
      <div className="form-wrap">
        <div className="about-det">
          <h1>Get in touch</h1>
          <p className="fs-4">
            Want to get in touch? We'd love to hear from you. Here's how you can
            reach us.
          </p>
        </div>
        <div className="contact-info">
          <div className="contact-form">
            <img
              className="formbg1"
              src="./images/Contact/bg1.png"
              alt="Form background"
            />
            <img
              className="formbg2"
              src="./images/Contact/bg2.png"
              alt="Form background"
            />
            <input className={(errors.name!=="") && 'error_input' } placeholder="Name" type="text"/>
			<p className="error_form">{errors.name}</p>
            <input className={errors.email!=="" && 'error_input' } placeholder="E-mail" type="email" id="" />
			<p className="error_form">{errors.email}</p>
            <PhoneInput
              placeholder="Enter phone number"
              value={num}
              onChange={setNum}
              style={{ zIndex: "100", alignSelf: "flex-start", width: "100%" }}
              defaultCountry="IN"
			  className={errors.phone!=="" && 'error_input_phone' }
            />

			<p className="error_form">{errors.phone}</p>
  
            <textarea
              style={{ resize: "none" }}
			  className={errors.message!=="" && 'error_input' }
              placeholder="Message"
              cols="30"
              rows="3"
            ></textarea>

			<p className="error_form">{errors.message}</p>
            
			<button
              onClick={onSubmission}
              type="button"
              className="contact-btn"
            >
              {isloading ? (
                <Loader
                  type="Oval"
                  color="#fff"
                  height={30}
                  width={30}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
      {!isTabletOrMobile && (
        <div className="contact-side">
          <img src="./images/Contact/contact.webp" alt="Dosa" />
        </div>
      )}

      <div className="faq-wrapper">
        <h1 className="text-center mb-5 fw-bold">Have any Questions?</h1>

        {loader ? (
          <Loader
            type="TailSpin"
            color="rgb(255, 165, 0)"
            height={70}
            width={70}
            className="text-center mt-5"
            style={{ height: "60vh" }}
          />
        ) : (
          <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {titles.map((title, i) => (
                <li key={i} className="nav-item" role="presentation">
                  <button
                    className={
                      i === 0
                        ? "nav-link active text-capitalize"
                        : "nav-link text-capitalize"
                    }
                    data-bs-toggle="tab"
                    data-bs-target={"#" + title.substring(0, 4)}
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    {title}
                  </button>
                </li>
              ))}
            </ul>

            <div className="tab-content" id="myTabContent">
              {titles.map((title, i) => (
                <div
                  className={
                    i === 0 ? "tab-pane fade show active" : "tab-pane fade"
                  }
                  id={title.substring(0, 4)}
                  role="tabpanel"
                  aria-labelledby="home-tab"
				  key={i}
                >
                  <div >
                    {content[title].map((data, j) => {
                      let ranid = makeid();
                      return (
                        <div
                          className="accordion accordion-flush"
                          id="accordionSection"
                          key={j}
                        >
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                data-bs-toggle="collapse"
                                id={"#" + ranid}
                                data-bs-target={"#" + ranid}
                                className="accordion-button collapsed shadow"
                                type="button"
                                style={{ marginBottom: 10, padding: 30 }}
                              >
                                {data.question}
                              </button>
                            </h2>
                            <div
                              className="accordion-collapse collapse"
                              id={ranid}
                              data-bs-parent="#accordionSection"
                            >
                              <div
                                style={{ whiteSpace: "pre-wrap" }}
                                className="accordion-body"
                              >
                                {data.answer}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <br />
                    <br />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Contact;
