import "./FAQ.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import Navbar from "../components/Navbar";

const FAQ = () => {
  const [content, setContent] = useState({});
  const [titles, setTitles] = useState([]);
  const [loader,setLoader] = useState(false);
  useEffect(() => {
	setLoader(true);
    getData();
  }, []);
  const getData = async () => {
    const res = await axios.get(
      process.env.REACT_APP_FAQ_API
    );
	
    setContent(res.data);

    let dataSet = [];

    for (let k in res.data) {
		if(k==='contactNumber')
		continue;
      dataSet.push(k);
    }
    setTitles(dataSet);
	setLoader(false);
  };

  if(loader===true)
  {
	  return (
		  <>
		<div className="faq-head">
        <h1>Frequently Asked Questions</h1>
      </div>
		<Loader
        type="TailSpin"
        color="rgb(255, 0, 0)"
        height={70}
        width={70}
		className="text-center mt-5"
		style={{height:'60vh'}}
        />
		</>
          
	  )
  }

  function makeid() {
	 let  length=5;
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}
  return (
    <>
      <Navbar />
      <div className="faq-head">
        <h1>Frequently Asked Questions</h1>
      </div>
      <div className="faq-body">
        {titles.map((title,i) => {
          return (
            <div key={i}>
              <h1 className="fw-bold" style={{textTransform: "capitalize" }}>{title}</h1>
			  <br/>
              {content[title].map((data,j) => {
				  let ranid = makeid();
                return (
                  <div
                    className="accordion accordion-flush"
                    id="accordionSection"
					key={j}
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          data-bs-toggle="collapse"
						  id={'#'+ranid}
                          data-bs-target={'#'+ranid}
                          className="accordion-button collapsed shadow"
                          type="button"
						  style={{marginBottom:10,padding:30}}
                        >
                          {data.question}
                        </button>
                      </h2>
                      <div
                        className="accordion-collapse collapse"
                        id={ranid}
                        data-bs-parent="#accordionSection"
                      >
                        <div style={{whiteSpace:'pre-wrap'}} className="accordion-body">{data.answer}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
			  <br/>
			  <br/>
            </div>
          );
        })}
		<br/>
      </div>
    </>
  );
};

export default FAQ;
