import React, { useState, useEffect } from "react";
import "./Register.css";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";
import Loader from "react-loader-spinner";

const VenRegister = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [resname, setRname] = useState("");
  const [ownname, setOname] = useState("");
  const [poc, setPoc] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [userData, setUser] = useState({});
  const db = firebase.firestore();
  function seconds_since_epoch(d) {
    return Math.floor(d / 1000);
  }

  useEffect(() => {
    setLoader(true);
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        setUser(user);

        let usersRef = db.collection("partneringRequest").doc(user.uid);
        usersRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            usersRef.onSnapshot((doc) => {
              const data = doc.data();
              if (data.vid) {
                setRname(data.restaurantName);
                setCity(data.city);
                setOname(data.ownerName);
                setCity(data.city);
                setEmail(data.email);
                setPoc(data.POC);
              }
              setLoader(false);
            });
          } else {
            usersRef.set({
              phoneNumber: user.phoneNumber,
              userid: user.uid,
            });
            setLoader(false);
          }
        });
      } else {
        // No user is signed in.
        history.push("/business/login");
      }
    });
    // eslint-disable-next-line
  }, []);

  const validation = (e) => {
    e.preventDefault();
    let venId = resname.replace(/ /g, "");
    if (venId.length > 15) {
      venId = venId.substring(0, 15);
    }
    var d = new Date();
    var sec = seconds_since_epoch(d);
    submitData(venId + sec);
  };

  const submitData = (venId) => {
    var output = db
      .collection("partneringRequest")
      .doc(userData.uid)
      .update({
        vid: venId,
        ownerName: ownname,
        phoneNumber: userData.phoneNumber,
        restaurantName: resname,
        email: email,
        POC: poc,
        city: city,
        userid: userData.uid,
        timestamp: firebase.firestore.Timestamp.now().toDate(),
      })
      .then((id) => {
        console.log(id);
      });
    console.log(output);
    history.push("/business/upload");
  };

  const signOut = () => {
    firebase.auth().signOut();
    history.push("/business/login");
  };

  const onEdit = (e) => {
    e.preventDefault();
    const inputs = document.querySelectorAll("input");
    const cityData = document.getElementById("city");
    cityData.disabled = false;
    for (var inp of inputs) inp.disabled = false;
    e.target.hidden = true;
    const alertContainer = document.getElementById("alert");
    alertContainer.innerHTML =
      '<div class="alert alert-info alert-dismissible fade show" role="alert"> <strong>Fields are Editable</strong><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
    // alertContainer.appendChild(alert);
  };

  return (
    <>
      {loader ? (
        <Loader
          type="TailSpin"
          color="rgb(255, 0, 0)"
          height={70}
          width={70}
          className="text-center mt-5"
          style={{ height: "60vh" }}
        />
      ) : (
        <>
          <div
            class="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <strong>Sign In Success</strong>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <div id="alert"></div>

			<nav className="regnav">
				<img style={{height:60,cursor:'pointer'}} src="../logos/logo4.png" alt=''/>
				<button onClick={signOut} className="btn btn-orange">Sign out</button>
			</nav>
			<div className="regheader">
			<h1>Partner With US</h1>
			</div>

			<div className="steps">
				<div>
					<h1 className="stepunactive">1</h1>
					<h4>Login</h4>
				</div>
				<div>
					<h1 className="stepactive">2</h1>
					<h4>Information</h4>
				</div>
				<div>
					<h1 className="stepunactive">3</h1>
					<h4>Documents</h4>
				</div>
			</div>

          <form onSubmit={validation} className="mx-4" noValidate>
            <div id="recaptcha-container"></div>
            <div className="reginp">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                1. Restaurant Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={resname}
                onChange={(e) => {
                  setRname(e.target.value);
                }}
                disabled
                required
              />
            </div>
            <div className="reginp">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                2. Owner Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={ownname}
                required
                onChange={(e) => {
                  setOname(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="reginp">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                3. Restaurant POC Designation{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={poc}
                required
                onChange={(e) => {
                  setPoc(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="reginp">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                4. Owner Email ID
              </label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled
              />
            </div>
            <div className="reginp">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                5. Restaurant City <span className="text-danger">*</span>
              </label>
              <select
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                className="form-select"
                id="city"
                aria-label="Default select example"
                disabled
                required
              >
                <option>Select City</option>
                <option value="banglore">Bangalore</option>
              </select>
            </div>
            <button onClick={onEdit} className="me-2 btn btn-orange">
              Edit
            </button>
            <button type="submit" className="btn btn-orange">
              Next
            </button>
          </form>
        </>
      )}
    </>
  );
};

export default VenRegister;
