import React from "react";
import {
	Link
  } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
	return(
		<div className="footer">
		<div className="fwrapper">
		<div className="about">
			<img src="./logos/streatu.svg" alt="Logo" />
			{/* <p style={{color:'#4c566a'}}>Lorem ipsum dolor amet, consectetur adipiscing elitroin pegestas.</p> */}
			<div className="social-icons">
             <a href="https://www.facebook.com/streatu.streeteats">
               <img alt="facebook" src="../social/facebook.svg" />
             </a>
             <a href="https://www.instagram.com/streatuindia/">
               <img alt="instagram" src="../social/instagram.svg" />
             </a>
			 <a href="https://www.youtube.com/channel/UCjvn3nWzvySZXWs-JO9N5OQ">
               <img alt="Youtube" src="../social/Youtube.svg" />
             </a>
             <a href="https://twitter.com/StrEat_U">
               <img alt="twitter" src="../social/twitter.svg" />
             </a>
           </div>
		</div>
		<div className="legal">
		<h4 className="fw-bold" style={{color:'#ffb43c'}}>Legal</h4>
           <Link to="/terms-conditions" >Terms of Use</Link>
        	<Link to="/privacy-policy" >Privacy Policy</Link>
			<Link to="/refund-policy">Refund & Cancellation</Link>
		</div>
		{/* <div className="legal">
		<h4 className="fw-bold" style={{color:'#ffb43c'}}>For Vendors</h4>
           <a href="/" >Add Vendor</a>
        	<Link to="/business">Business App</Link>
		</div> */}
		<div className="legal">
		<h4 className="fw-bold" style={{color:'#ffb43c'}}>Contact Email</h4>
			<a href="mailto: support@streatu.com">support@streatu.com</a> 
		</div>
		
		</div>
		      <div style={{ fontSize: 15, margin: "20px", color: "#4c566a" }}>
        © 2022 Streatu Reserve All Rights
       </div>
		
		</div>
	);
};


export default Footer;
