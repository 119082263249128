import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/storage"
import "firebase/auth"
import "firebase/firebase-app-check"


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,

  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,

  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,

  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,

  storageBucket: "streatu-6cdea.appspot.com",

  messagingSenderId: "763353557123",

  appId: "1:763353557123:web:97b0fefe84b8c863ab53b6",

  measurementId: "G-X0D8ZVYN8Z",
};

firebase.initializeApp(firebaseConfig);

const appCheck = firebase.appCheck();
appCheck.activate(
  '6LcpiwEcAAAAAEZo4B5J_0QQoByBCRzb1Uyd7bUQ');

export default firebase;