import React from "react";
import "./Policy.scss";
import Navbar from "../components/Navbar";
import PolicyTabs from "../components/PolicyTabs";

const Policy = () => {
  return (
    <>
      <div className="policy-cont">
        <Navbar isDark={true} />
        <div className="policy-head">Privacy Policy</div>
      </div>
      <PolicyTabs link={process.env.REACT_APP_POLICY_API} />
    </>
  );
};

export default Policy;
