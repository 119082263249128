import {
	Switch,
	Route,
	Redirect
  } from "react-router-dom";
import React from "react";
import About from "./screens/About";
import Contact from "./screens/Contact";
import Footer from "./components/Footer";
import Policy from "./screens/Policy";
import ScrollToTop from "./components/ScrollToTop";
import Terms from "./screens/Terms";
import FAQ from "./screens/FAQ";
import CancelPolicy from "./screens/CancelPolicy";
import Vendor from "./screens/Vendor"
import VenRegister from "./screens/VendorRegistration/VenRegister";
import VendorLogin from "./screens/VendorRegistration/VendorLogin";
import UploadDocs from "./screens/VendorRegistration/UploadDocs";
import Home from "./screens/Home";
import Notfound from "./screens/Notfound";

function App() {

	document.addEventListener('scroll', function(e) {
		if(window.scrollY>=500)
		{
			if(document.getElementById('topScroll') !=null)
			document.getElementById('topScroll').style.display="flex";
		}
		else{
			if(document.getElementById('topScroll') !=null)
			document.getElementById('topScroll').style.display="none";
		}
	});


  return (
	  <>
	<ScrollToTop/>
	<div id="topScroll" onClick={(e)=> window.scrollTo(0,0)}>
		<img src="./images/rocket.svg" alt="rocket" />
	</div>
    <Switch>
		<Route exact path="/" >
			<Redirect to="/home" />
		</Route>
		<Route exact path="/home" component={Home} />
		<Route exact path="/about" component={About} />
		<Route exact path="/contact" component={Contact} />
		<Route exact path="/privacy-policy" component={Policy} />
		<Route exact path="/terms-conditions"  component={Terms}/>
		<Route exact path="/FAQ" component={FAQ}/>
		<Route exact path="/refund-policy" component={CancelPolicy} />
		<Route exact path="/business" component={Vendor} />
		<Route exact path="/business/login" component={VendorLogin}/>
		<Route exact path="/business/register" component={VenRegister}/>
		<Route exact path="/business/upload" component={UploadDocs}/>
		
		<Route component={Notfound} />
	</Switch>
	<Footer/>
	</>
  );
}

export default App;
