import React from "react";
import './Vendor.css'
import {Link} from 'react-router-dom'
import "./VendorRegistration/Register.css";

const Vendor = () => {
  return (
    <>
	<nav className="loginnav">
        <img
          style={{ cursor: "pointer", width: 200 }}
          src="../logos/logo4.png"
		  alt="Streatu"
        />
      </nav>
	<div className="ven_bgimg"></div>
	  <div className="ven_bg container">
		<div className="ven_block row">
			<div className="ven_headDetail col-lg-6  col-sm-12">
				<h1>Partner with StrEatU</h1>
				<p >Get listed on India's First Street food delivery platform</p>
			</div>
			<div className="col col-lg-6 col-sm-12">
			<div className="ven_require">
				<h1>Get Started</h1>
				<span style={{color:'grey',fontWeight:'bold'}}>Keep the following documents ready!</span>
				<ul>
					<li><i className="fas fa-check-circle"></i>FSSAI License copy</li>
					<li><i className="fas fa-check-circle"></i>Aadhar copy (for KYC)</li>
					<li><i className="fas fa-check-circle"></i>Your Restaurant Menu</li>
					<li><i className="fas fa-check-circle"></i>PAN card copy</li>
					<li><i className="fas fa-check-circle"></i>Bank Details</li>
				</ul>
				<Link to="/business/login" style={{marginLeft:20}} className="btn btn-danger">Join us</Link>
			</div>
			</div>
		</div>
	  </div>

    </>
  );
};

export default Vendor;
