import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";
import "./Register.css";

const UploadDocs = () => {
  const [pan, setGst] = useState(null);
  const [gst, setPan] = useState(null);
//   const [isPan,setisPan] = useState(false);
//   const [isGst,setisGst] = useState(true);
//   const [isMenu,setisMenu] = useState(false);
  const [userData, setUserData] = useState({});
  const db = firebase.firestore();
  const history = useHistory();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.

        let usersRef = db.collection("partneringRequest").doc(user.uid);
        usersRef.get().then((docSnapshot) => {
          usersRef.onSnapshot((doc) => {
            setUserData(doc.data());
            console.log(doc.data());
          });
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  const signOut = () => {
    firebase.auth().signOut();
    history.push("/business/login");
  };

  const handleUpload1 = (e) => {
    const file = e.target.files[0];
    setGst(file);
  };
  const handleUpload2 = (e) => {
    const file = e.target.files[0];
    setPan(file);
  };

  const handleUpload = (e) => {
    var storageRef = firebase.storage().ref();
    //onboardingDoc/{city}/{vendorID}/{userId}
	if(pan===null || gst===null)
	{
		return;
	}
    var innerRef1 = storageRef.child(
      `/onboardingDoc/${userData.city}/${userData.userid}/${pan.name}`
    );
    var innerRef2 = storageRef.child(
      `/onboardingDoc/${userData.city}/${userData.userid}/${gst.name}`
    );
    innerRef2.put(gst).then((snapshot) => {
      console.log("Uploaded GST");
    });
    innerRef1.put(pan).then((snapshot) => {
      console.log("Uploaded PAN");
    });
  };
  return (
    <>
      <nav className="regnav">
        <img
          style={{ height: 60, cursor: "pointer" }}
          src="../logos/logo4.png"
		  alt=''
        />
        <button onClick={signOut} className="btn btn-orange">
          Sign out
        </button>
      </nav>
      <div className="regheader">
        <h1>Partner With US</h1>
      </div>

	  <div className="steps">
				<div>
					<h1 className="stepunactive">1</h1>
					<h4>Login</h4>
				</div>
				<div>
					<h1 className="stepunactive">2</h1>
					<h4>Information</h4>
				</div>
				<div>
					<h1 className="stepactive">3</h1>
					<h4>Documents</h4>
				</div>
			</div>

      <div className="regupload">
        Upload GST Certificate <span className="text-danger">*</span>
		{
			(true) ? <div className="uploadedAlert">
			<i style={{color:'green',marginRight:20}} className="fas fa-check-circle"></i>
			Uploaded
			</div>  : <input
          className="form-control"
          type="file"
          onChange={handleUpload1}
        />
		}
        
        Upload PAN <span className="text-danger">*</span>
        <input
          className="form-control"
          type="file"
          onChange={handleUpload2}
        />
        Upload Menu <span className="text-danger">*</span>
        <input
          className="form-control"
          type="file"
          onChange={handleUpload1}
        />
        <button onClick={handleUpload} className="m-3 btn btn-orange">
          Upload
        </button>
      </div>
    </>
  );
};

export default UploadDocs;
