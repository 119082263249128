import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";
import Loader from "react-loader-spinner";
import "./Register.css";

const VendorLogin = () => {
  const [otp, setOtp] = useState("");
  const [isverify, setVerify] = useState(false);
  const [number, setNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        history.push("/business/register");
        console.log(user);
      } else {
      }
    });
    // eslint-disable-next-line
  }, []);

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log("Captcha Resolved");
          setLoader(true);
          onSignInSubmit();
          window.recaptchaVerifier.clear();
        },
      }
    );
  };

  const onSignInSubmit = () => {
    setUpRecaptcha();
    var phoneNumber = "+91" + number;
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoader(false);
        setVerify(true);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const verifyOTP = () => {
    setLoader(true);
    var code = otp;
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        setLoader(false);
        history.push("/business/register");
      })
      .catch((e) => {
        console.log(e);
        setLoader(false);
        document.getElementById("confirm").innerHTML = "Wrong OTP";
      });
  };

  const createVerify = () => {
    return (
      <>
        <input
          type="number"
          className="form-control"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
        />
        <button onClick={verifyOTP} className="btn-orange btn">
          Verify otp
        </button>
      </>
    );
  };

  return (
    <>
      <nav className="loginnav">
        <img
          style={{ cursor: "pointer", width: 200 }}
          src="../logos/logo4.png"
		  alt=""
        />
      </nav>
	  <div className="regheader">
          <h1>Partner With US</h1>
        </div>
      <div className="login">

        <input
          value={number}
          onChange={(e) => {
            setNumber(e.target.value);
          }}
          required
          type="number"
          placeholder="Enter Phone no."
          className="form-control"
        />

        {!isverify && (
          <button onClick={onSignInSubmit} className="btn btn-orange">
            Register
          </button>
        )}

        {isverify && createVerify()}

        {loader && (
          <Loader
            type="TailSpin"
            color="rgb(255, 0, 0)"
            height={70}
            width={70}
            className="text-center mt-5"
            style={{ height: "60vh" }}
          />
        )}
        <div id="confirm"></div>
        <div id="recaptcha-container"></div>
      </div>
    </>
  );
};

export default VendorLogin;
