// import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Nav from "../components/Nav";
import "./Homepage.scss";
import { useMediaQuery } from "react-responsive";
// import CookieShow from "../components/CookieShow";
// import Cookies from 'universal-cookie';

const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
//   const [cookie, setcookie] = useState(false)
//   const cookies = new Cookies();
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    className: "slides2",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
//    useEffect(() => {

//    }, [cookie])

//   const setCookie = ()=>{
// 	cookies.set('cookie-consent', 'true', { path: '/' });
// 	setcookie(false);
//   }

//   const rejectCookie = ()=>{
// 	setcookie(false);
//   }

//   window.onload = ()=>{
// 	if(!cookies.get("cookie-consent"))
// 	{
// 		setcookie(true);
// 	}

//   }
  

  return (
    <>
	
	{/* {cookie && <CookieShow setCookie={setCookie} rejectCookie={rejectCookie}/>} */}
      <div className="home-wrapper">
	  <Nav/>
        {isMobile ? (
				<img loading="lazy" className="home-bg-mb" src="./images/home1-mb.png" alt="App Img" />
        ) : (
				<img loading="lazy" className="home-bg" src="./images/home1.png" alt="App Img" />
        )}

        <div className="home-det">
          <h1>Great Street Food</h1>
          <h4>
            delivered at super <br /> affordable prices.
          </h4>
          <div className="store-icons-wrapper">
            <a href="https://play.google.com/store/apps/details?id=com.fleetofleet.streatu&hl=en_IN&gl=US">
              <img
                src="./images/Google_Play.png"
                srcSet="./images/Google_Play@2x.png 2x,./images/Google_Play@3x.png 3x"
                alt="Google play"
              />
            </a>{" "}
            <a href="https://apps.apple.com/us/app/streatu/id1554393304">
              <img
                src="./images/App_Store.png"
                srcSet="./images/App_Store@2x.png 2x,./images/App_Store@3x.png 3x"
                alt="Apple"
              />
            </a>
          </div>
        </div>

        <div className="home-side-img">
          <img src="./images/img3.webp" alt="Pani puri" />
        </div>

        <div className="slider-cards">
          <p style={{ color: "#ffa223" }} className="fw-bold fs-1 text-center">
            3 simple steps
          </p>

          <p
            style={{ color: "#4c566a", marginBottom: "5rem" }}
            className="text-center fs-5 fw-bold"
          >
            Street food, delivered!
          </p>

          <Slider {...settings1}>
            <div className="card-wrapper">
              <div className="outer-card">
                <img src="./images/img7.png" alt="Location" />
                <div className="home-card">
                  <h2 className="fw-bold fs-4 text-center">
                    Enter Your location
                  </h2>
                </div>
              </div>
            </div>

            <div className="card-wrapper">
              <div className="outer-card">
                <img src="./images/img8.png" alt="Vendor" />
                <div className="home-card">
                  <h2 className="fw-bold fs-4 text-center">
                    Choose your favourite vendor
                  </h2>
                </div>
              </div>
            </div>

            <div className="card-wrapper">
              <div className="outer-card">
                <img src="./images/img6.svg" alt="Delivery Guy" />
                <div className="home-card">
                  <h2 className="fw-bold fs-4 text-center">
                    Pay, and get your food delivered
                  </h2>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        <div className="homesec-3">
		{isTabletOrMobile ? <img src="./images/mask-1-mb.png" alt="Mask guy" /> : <img src="./images/mask-1.webp" alt="Mask guy" />}

          <div className="sec3_wrap">
            <div className="sec3_info">
              <img src="./images/g1.svg" alt="Store" />
              <h2 style={{ color: "#38da9f" }}>Curated Vendors</h2>
              <p>
                Only safe-to-eat-from places. <br /> The most popular places.{" "}
                <br /> The tastiest of places.
              </p>
            </div>


            <div className="sec3_info">
              <img src="./images/g4.svg" alt="Money" />
              <h2 style={{ color: "#ff7d69" }}>Reasonable Prices</h2>
              <p>
                Download the app to find out. <br /> High quality food that's surprisingly{" "}
                <br /> easy on the pocket
              </p>
            </div>
			<div className="sec3_info">
              <img src="./images/g2.svg" alt="Discount" />
              <h2 style={{ color: "#b75def" }}>Attractive Discounts</h2>
              <p>
                Instant cashbacks, surprise offers,
                <br /> flat discount coupons and a lot more. <br />
                Download the App now!
              </p>
            </div>

            <div className="sec3_info">
              <img src="./images/g3.svg" alt="Map" />
              <h2 style={{ color: "#6fa2f5", marginTop: 10 }}>Coming Soon</h2>
              <p>
                Over 70 vendors waiting for your <br /> order in North
                Bangalore. Coming <br /> soon to your locality!
              </p>
            </div>
          </div>
        </div>

        <div className="burger-img">
          <img src="./images/burger.webp" alt="Burger" />
        </div>

        {/* Testimonials */}
{/* 
        <p
          style={{ color: "#ffa223", marginTop: 100 }}
          className="fw-bold fs-1 text-center"
        >
          What food lovers are saying
        </p>


        <Slider {...settings2}>
          <div className="card-wrapper">
            <div className="review-card">
              <img src="./images/people/p1.webp" alt="Review 1" />
              <div className="review">
                <h2 className="fw-bold fs-4 text-center">Ridhima Sinha</h2>
                <p>
                  Generate Lorem Ipsum placeholder text for use in your graphic,
                  print and web layouts, and discover plugins for your favourite
                  writing, design
                </p>
              </div>
            </div>
          </div>

          <div className="card-wrapper">
            <div className="review-card">
              <img src="./images/people/p2.webp" alt="Review 2" />
              <div className="review">
                <h2 className="fw-bold fs-4 text-center">Priyanka Rai</h2>
                <p>
                  "Generate Lorem Ipsum placeholder text for use in your
                  graphic, print and web layouts, and discover plugins for your
                  favourite writing, design"
                </p>
              </div>
            </div>
          </div>

          <div className="card-wrapper">
            <div className="review-card">
              <img src="./images/people/p3.webp" alt="Review 3" />
              <div className="review">
                <h2 className="fw-bold fs-4 text-center">Praksah Katekar</h2>
                <p>
                  Generate Lorem Ipsum placeholder text for use in your graphic,
                  print and web layouts, and discover plugins for your favourite
                  writing, design
                </p>
              </div>
            </div>
          </div>
        </Slider> */}
      </div>
    </>
  );
};

export default Home;
