import React, { useEffect, useState } from "react";
import "../screens/Policy.scss";
import axios from "axios";
import Loader from "react-loader-spinner";

const TermsTabs = (props) => {
  const [active, setActive] = useState("@GENERAL");
  const [content, setContent] = useState({});
  const [topics, setTopic] = useState([]);
  const [cancelToken, setCancelToken] = useState(undefined);
  const [loader,setLoader] = useState(false);
  useEffect(() => {
	setLoader(true);
    getData();
	// eslint-disable-next-line
  }, []);

  function ResponseText(props) {
    const text = props.text;
    return <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>;
  }

  const getData = async () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Canceling the previous req");
    }
    setCancelToken(axios.CancelToken.source());
    const res = await axios.get(
    props.link,
      {
        cancelToken: axios.CancelToken.source.token,
      }
    );
	console.log(res.data);
    setContent(res.data);
    let listData = res.data["privacyPolicy"];

    let topicList = [];
    for (let a in listData) {
      for (let key in listData[a]) {
        topicList.push(key);
      }
    }
    setTopic(topicList);
	setLoader(false);
	console.log(topicList);
  };
  
  if(loader===true)
  {
	  return (
		<Loader
        type="TailSpin"
        color="#ffa223"
        height={70}
        width={70}
		className="text-center mt-5"
		style={{height:'60vh'}}
        />
          
	  )
  }

  const scrollLeft= ()=>{
	const cardScroll = document.getElementsByClassName('card-cont')[0];
	cardScroll.scrollLeft -= 500;
  }
  const scrollRight= ()=>{
	document.getElementById('left').style.visibility = 'visible';
	const cardScroll = document.getElementsByClassName('card-cont')[0];
	cardScroll.scrollLeft += 500;
}


  const handleClick = (e) => {
    setActive(e.target.id);
    if (e.target.id !== active) {
      document.getElementById(e.target.id).classList.add("card-active");
      document.getElementById(active).classList.remove("card-active");
    }
    var elmntToView = document.getElementById(e.target.id.substring(1));
    if (elmntToView) {
      elmntToView.scrollIntoView();
      window.scrollTo(0, elmntToView.offsetTop - 50);
    }
  };


  return (
	<div>
	<div className="update-date">
	<p className="fw-bold">
        Effective Date : {content["Effective Date"]}
      </p>
	  <span>|</span>
      <p className="fw-bold">
        Last Updated On : {content["Last updated on"]}
      </p>
	</div>

      <div className="policy-body">
        <div className="sidebar">
		<button id="left" className="scroll-btn" onClick={scrollLeft}><i className="fas fa-chevron-left"></i></button>
          <div className="card-cont">
            {topics.map((e) => {
              return <Card key={e} fun={handleClick} title={e} />;
            })}
          </div>
		  <button id="right" className="scroll-btn" onClick={scrollRight}><i className="fas fa-chevron-right"></i></button>
        </div>

        <div className="content" id="setData">
          {topics.map((title, i) => {
            return (
              <div key={i} id={title} className="policy-div">
                <b>
                  {i + 1}. {title}
                </b>{" "}
                <br />
                <br />
                <ResponseText text={content["privacyPolicy"][i][topics[i]]} />
              </div>
            );
          })}
        </div>
      </div>
	</div>
  );
};

export default TermsTabs;

const Card = ({ fun, id, title }) => {
  return (
    <button
      id={"@" + title}
      onClick={fun}
      className={title === "GENERAL" ? "card card-active" : "card"}
    >
      {title}
    </button>
  );
};
