import React from 'react'
import { useHistory } from 'react-router'
import Navbar from "../components/Navbar"

const Notfound = () => {
	const history = useHistory()
	const redirectHome = ()=>{
		history.push('/')
	}
	
	return (
		<div>
		<Navbar isDark={true}/>
		<div className="d-flex justify-content-center align-items-center " style={{height:'40vh',flexDirection:'column'}}>
			<h1 className="text-center fw-bold mb-5" >Page Not Found !</h1>
			<button onClick={redirectHome} className="contact-btn">Back to Home</button>
		</div>

		</div>
	)
}

export default Notfound
