import React from 'react'
import "./Policy.scss";
import Navbar from "../components/Navbar";
import TermsTabs from '../components/TermsTabs'

const Terms = () => {
	return (
		<>
		<Navbar isDark={true}/>
		  <div className="policy-head">
			Terms & Conditions
		  </div>
	  <TermsTabs link={process.env.REACT_APP_TERMS_API}/>
	  </>
	)
}

export default Terms
