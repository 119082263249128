import React, { useState } from "react";
import "./Navbar.scss";
import { Link, NavLink } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

const Nav = ({isDark}) => {
  const [clicked, setClicked] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleClick = () => {
    setClicked(!clicked);
  };
  return (
    <nav className={` NavbarItems ${isDark && "darknav"}` }>
      <div className = 'nav-logo'>
        {!isTabletOrMobile ? (
          <Link to="/">
            <img
              alt="logo"
              style={{ height: 60 }}
              className="logo-icon"
              src="./logos/logo1.webp"
            ></img>
          </Link>
        ) : (
			(isDark) ?
          <Link to="/">
            <img
              alt="logo"
              style={{ height: 45 }}
              className="logo-icon"
              src="./logos/logodark.svg"
            ></img>
          </Link> :  <Link to="/">
            <img
              alt="logo"
              style={{ height: 45 }}
              className="logo-icon"
              src="./logos/logo2.png"
            ></img>
          </Link>
        )}
      </div>

      <div className="menu-icon" onClick={handleClick}>
	  {clicked ? <i className="fa fa-times"/> : <img src="./icons/nav1.svg" alt="open" /> }
      </div>
      <ul style={{padding:0}} className={clicked ? "nav-menu active" : "nav-menu" }>
        <li>
          <NavLink exact to="/" activeClassName="curr" className="nav-link nav-white" >
		  {isTabletOrMobile && <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <path data-name="Subtraction 25" d="M16 32A16 16 0 0 1 9.772 1.257a16 16 0 0 1 12.456 29.486A15.9 15.9 0 0 1 16 32zm3.521-9.161c.513 0 1.031 0 1.54.014h.039a.32.32 0 0 0 .24-.071.373.373 0 0 0 .06-.282c-.014-.592-.013-1.2-.011-1.785v-3.002a.374.374 0 0 1 .065-.282.315.315 0 0 1 .238-.069h.038c.185.007.383.011.6.011.279 0 .566-.006.818-.011h.176a.232.232 0 0 0 .241-.141c.08-.187-.041-.308-.158-.424l-.022-.022-1.757-1.845c-1.74-1.829-3.54-3.721-5.305-5.593a.366.366 0 0 0-.644.01c-.54.6-1.1 1.186-1.65 1.757l-.558.585-2.431 2.559-2.433 2.558-.029.029c-.117.115-.238.234-.151.417a.229.229 0 0 0 .235.132h.992c.239 0 .433-.005.612-.016h.076c.287 0 .37.1.37.448-.018.969-.017 1.958-.016 2.915v1.73a.425.425 0 0 0 .073.32.369.369 0 0 0 .278.079h.038a79.684 79.684 0 0 1 2.938 0 .422.422 0 0 0 .314-.085.428.428 0 0 0 .075-.322c-.009-.45-.007-.908 0-1.351s0-.9 0-1.351a.4.4 0 0 1 .073-.3.349.349 0 0 1 .262-.078h.035c.41.009.825.014 1.232.014s.822 0 1.232-.014h.035a.372.372 0 0 1 .278.081.434.434 0 0 1 .076.327c-.009.45-.007.908 0 1.351s0 .9 0 1.351c0 .288.081.38.335.38h.035a39.477 39.477 0 0 1 1.516-.024z" style={{fill:'#4c566a'}}/>
</svg> }  Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" activeClassName="curr" className="nav-link nav-white" >
		  {isTabletOrMobile && <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <path data-name="Subtraction 23" d="M16 32A16 16 0 0 1 9.772 1.257a16 16 0 0 1 12.456 29.486A15.9 15.9 0 0 1 16 32zm-.059-14.447c-1.083 0-2.17.02-3.23.058a3.6 3.6 0 0 0-3.327 2.114 7.1 7.1 0 0 0-.616 2.075c-.056.387-.033.6.079.727s.318.183.708.183h6.4c2.158 0 4.389 0 6.588-.01a.754.754 0 0 0 .6-.184.775.775 0 0 0 .079-.641 10.8 10.8 0 0 0-.218-1.061 3.843 3.843 0 0 0-4.037-3.214 99.595 99.595 0 0 0-3.026-.047zm.164-8.273a3 3 0 0 0-2.569 1.269 4.589 4.589 0 0 0 .275 5.071 2.988 2.988 0 0 0 2.314 1.142 3.026 3.026 0 0 0 2.375-1.226 4.068 4.068 0 0 0 .825-2.673 4.792 4.792 0 0 0-.1-1.062 2.907 2.907 0 0 0-2.285-2.436 4.292 4.292 0 0 0-.835-.084z" style={{fill:'#4c566a'}}/>
</svg> }About Us
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" activeClassName="curr" className="nav-link nav-white" >
		  {isTabletOrMobile && <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g data-name="18238319521579178000">
        <path data-name="Subtraction 26" d="M16 32a16.12 16.12 0 0 1-3.224-.325 15.912 15.912 0 0 1-5.721-2.407 16.045 16.045 0 0 1-5.8-7.04 15.907 15.907 0 0 1-.932-3 16.153 16.153 0 0 1 0-6.449 15.917 15.917 0 0 1 2.41-5.725 16.048 16.048 0 0 1 7.04-5.8 15.916 15.916 0 0 1 3-.932 16.155 16.155 0 0 1 6.449 0 15.914 15.914 0 0 1 5.721 2.407 16.044 16.044 0 0 1 5.8 7.04 15.914 15.914 0 0 1 .932 3 16.156 16.156 0 0 1 0 6.449 15.913 15.913 0 0 1-2.407 5.721 16.045 16.045 0 0 1-7.04 5.8 15.916 15.916 0 0 1-3 .932A16.121 16.121 0 0 1 16 32zM11.136 8.481a1.631 1.631 0 0 0-.9.347 4.06 4.06 0 0 0-1.658 4.345 8.888 8.888 0 0 0 .672 1.857 17.153 17.153 0 0 0 8.128 7.9 6.107 6.107 0 0 0 2.511.6 3.646 3.646 0 0 0 3.27-1.588 1.6 1.6 0 0 0 .374-1 1.491 1.491 0 0 0-.537-.9 8.261 8.261 0 0 0-1.217-.947c-.243-.166-.495-.338-.731-.523a2.333 2.333 0 0 0-1.42-.579 1.952 1.952 0 0 0-1.266.521 1.514 1.514 0 0 1-.3.17l-.1.05a2.763 2.763 0 0 1-1.2.316 1.879 1.879 0 0 1-.827-.19 2.811 2.811 0 0 1-.784-.581 21.832 21.832 0 0 1-1.471-1.647 2.247 2.247 0 0 1-.587-1.4 2.011 2.011 0 0 1 .587-1.387 1.63 1.63 0 0 0 .163-2.067 17.225 17.225 0 0 0-1.3-2.061c-.121-.173-.247-.353-.369-.531a1.269 1.269 0 0 0-1.022-.686z" style={{fill:'#4c566a'}}/>
    </g>
</svg> }
Contact Us
          </NavLink>
        </li>

		{
		(isTabletOrMobile) && <div className="about">
			{/* <p style={{color:'#4c566a'}}>Lorem ipsum dolor amet, consectetur adipiscing elitroin pegestas.</p> */}
			<div className="social-icons nav-social">
             <a href="https://www.facebook.com/streatu.streeteats">
               <img alt="facebook" src="../social/facebook.svg" />
             </a>
             <a href="https://www.instagram.com/streatu.streeteats/">
               <img alt="instagram" src="../social/instagram.svg" />
             </a>
			 <a href="https://www.youtube.com/channel/UCjvn3nWzvySZXWs-JO9N5OQ">
               <img alt="Youtube" src="../social/Youtube.svg" />
             </a>
             <a href="https://twitter.com/StrEat_U">
               <img alt="twitter" src="../social/twitter.svg" />
             </a>
           </div>
		</div>

		}
		{
			(isTabletOrMobile) &&
			(
			(isDark) ?
			<>
			<div className="legal nav-legal-dark ms-3">
		<h5 className="fw-bold fs-6" style={{color:'#ffb43c'}}>Legal</h5>
           <Link to="/terms-conditions" >Terms of Use</Link>
        	<Link to="/privacy-policy" >Privacy Policy</Link>
			<Link to="/refund-policy">Refund & Cancellation</Link>
		</div>
		<div className="legal nav-legal-dark ms-3 fs-6">
		<h4 className="fw-bold fs-6" style={{color:'#ffb43c'}}>Contact Email</h4>
			support@streatu.com
		</div>
		      <div style={{ fontSize: 12, margin: "20px", color: "#dbdee7" }}>
        © 2021 Streatu Reserve All Rights
       </div>
	   </>
			:
			<>
			<div className="legal nav-legal ms-3">
		<h5 className="fw-bold fs-6" style={{color:'#ffb43c'}}>Legal</h5>
           <Link to="/terms-conditions" >Terms of Use</Link>
        	<Link to="/privacy-policy" >Privacy Policy</Link>
			<Link to="/refund-policy">Refund & Cancellation</Link>
		</div>
		<div className="legal nav-legal ms-3 fs-6">
		<h4 className="fw-bold fs-6" style={{color:'#ffb43c'}}>Contact Email</h4>
			support@streatu.com
		</div>
		      <div style={{ fontSize: 12, margin: "20px", color: "#4c566a" }}>
        © 2021 Streatu Reserve All Rights
       </div>
			</>

			)
		}

      </ul>
    </nav>
  );
};

export default Nav;
